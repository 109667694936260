<div class="modal-header" *ngIf="IsModal">
    <h5 class="modal-title" id="editUserLabel">{{ CrudSettings.Label ?? 'Edit'  }}</h5>
    <button type="button" class="close" (click)="close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<div [ngClass]="{'': IsModal, 'card': !IsModal }" *ngIf="CrudSettings?.campi && !Refreshing && Item">
    <div [ngClass]="{'modal-body': IsModal, 'card-body': !IsModal }">
      <app-field-viewer [(Oggetto)]="Item" [Campi]="CrudSettings.campi" (CustomButton)="customButton(Item, $event)" 
                        (OnChange)="ChangeHandler($event)"></app-field-viewer>
    </div>
    
  <div [ngClass]="{'': IsModal, 'card': !IsModal }" *ngIf="CrudSettings?.sezioni && !Refreshing && Item">
    <div [ngClass]="{'modal-body': IsModal, 'card-body': !IsModal }">
      <app-sector-viewer [(DropdownSources)]="DropdownSources" (OnFilter)="FilterHandler($event)" (CustomButton)="customButton(Item, $event)" 
                         (OnChange)="ChangeHandler($event)" [(Campi)]="CrudSettings.sezioni"
                         [(Oggetto)]="Item"></app-sector-viewer>
  
    </div>
  </div>
  <div class="d-md-flex align-items-center justify-content-end m-b-10 m-r-10">
    <ng-container *ngIf="!HideButtons">
      <ng-container *ngFor="let button of CrudSettings['Azioni']">
        <button type="submit" type="button" [ngClass]="button['class']" [ngbTooltip]="button.placeholder ?? ''"
                *ngIf="(button.nome != 'Elimina' || !HideDelete ) && campoUtility.ButtonShow(button,Item)"
                (click)='customButton(Item,button)'>
          <i *ngIf="button['icon']" [ngClass]="button['icon']" class="font-14 mr-1 text-white"></i>
          <span [ngClass]="button['labelClass']"> {{ button['nome'] | translate }} </span>
        </button>
      </ng-container>
    </ng-container>
    <button type="submit" (click)="save(ExternalAdd)" style="margin: 0.75px;" class=" btn btn-success  waves-effect waves-light">
      <span class="mr-1 text-white "> Salva </span>
      <i class="far fa-save text-white"></i>
    </button>
    <button type="submit" *ngIf="!ExternalAdd" (click)="save(true)" style="margin: 0.75px;"
            class=" btn btn-success  waves-effect waves-light">
      <span class="mr-1 text-white "> Salva e chiudi </span>
      <i class="far fa-save text-white"></i>
    </button>
    <button type="submit" (click)="close()"  style="margin: 0.75px;" class=" btn btn-danger  waves-effect waves-light">
      <span class="mr-1 text-white "> Chiudi </span>
      <i class="far fa-window-close text-white"></i>
    </button>
    <app-stampa [Stampe]="Stampe" *ngIf="Stampe?.length > 0"></app-stampa>
</div>
</div>
