import { Component, HostListener, forwardRef, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";
import { ListItem, IDropdownSettings } from "../../../../LibrerieCustom/MultiselectDropdown/multiselect.model";

export const DROPDOWN_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => autocompleteInputComponent),
  multi: true
};
const noop = () => { };


@Component({
  selector: "app-autocompleteInput",
  templateUrl: "./AutocompleteInput.component.html",
  styleUrls: ["./AutocompleteInput.component.scss"],
  providers: [DROPDOWN_CONTROL_VALUE_ACCESSOR
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class autocompleteInputComponent implements ControlValueAccessor {
  public _settings: IDropdownSettings;
  public _data: Array<ListItem> = [];
  public selectedItems:  any | Array<ListItem> = [];
  public isDropdownOpen = true;
  _placeholder = "Select";
  listFocused = false;
  private _sourceDataType = null; // to keep note of the source data type. could be array of string/number/object
  private _sourceDataFields: Array<String> = []; // store source data fields names
  filter: ListItem = new ListItem(this.data);
  defaultSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "text",
    disabledField: "isDisabled",
    enableCheckAll: true,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    allowSearchFilter: false,
    limitSelection: -1,
    clearSearchFilter: true,
    maxHeight: 197,
    itemsShowLimit: 999999999999,
    searchPlaceholderText: "Search",
    noDataAvailablePlaceholderText: "No data available",
    noFilteredDataAvailablePlaceholderText: "No filtered data available",
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false,
    allowRemoteDataSearch: false,
    moreButton: false,
    moreButtonText: "More"
  };

  @Input()
  public set placeholder(value: string) {
    if (value) {
      this._placeholder = value;
    } else {
      this._placeholder = "Select";
    }
  }
  @Input()
  disabled = false;

  @Input()
  public set settings(value: IDropdownSettings) {
    if (value) {
      this._settings = Object.assign(this.defaultSettings, value);
    } else {
      this._settings = Object.assign(this.defaultSettings);
    }
  }

  @Input()
  public set data(value: Array<any>) {
    if (!value) {
      this._data = [];
    } else {
      const firstItem = value[0];
      this._sourceDataType = typeof firstItem;
      this._sourceDataFields = this.getFields(firstItem);
      this._data = value;
      // this._data = value.map((item: any) =>
      //   typeof item === "string" || typeof item === "number"
      //     ? new ListItem(item)
      //     : new ListItem({
      //         id: item[this._settings.idField],
      //         text: item[this._settings.textField],
      //         isDisabled: item[this._settings.disabledField]
      //       })
      // );
    }
  }

  @Output("onFilterChange")
  onFilterChange: EventEmitter<ListItem> = new EventEmitter<any>();
  @Output("onDropDownClose")
  onDropDownClose: EventEmitter<ListItem> = new EventEmitter<any>();

  @Output("onSelect")
  onSelect: EventEmitter<ListItem> = new EventEmitter<any>();

  @Output("onDeSelect")
  onDeSelect: EventEmitter<ListItem> = new EventEmitter<any>();

  @Output("onSelectAll")
  onSelectAll: EventEmitter<Array<ListItem>> = new EventEmitter<Array<any>>();

  @Output("onDeSelectAll")
  onDeSelectAll: EventEmitter<Array<ListItem>> = new EventEmitter<Array<any>>();

  @Output("onMoreButtonClick")
  onMoreButtonClick: EventEmitter<ListItem> = new EventEmitter<any>();

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  onFilterTextChange($event) {
    this.writeValue($event);
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private eRef: ElementRef
  ) { }
  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      console.log("Click fuori dal dropdown, lo chiudo!");
      this._settings.defaultOpen = false;
    }
  }

  onItemClick(item: any) {
    console.log('Item clicked: ', item); 
    if (this.disabled || item?.isDisabled || !item) {
      return false;
    }
    const allowAdd = this._settings.limitSelection === -1 || this._settings.singleOutput || (this._settings.limitSelection > 0 && this.selectedItems?.length < this._settings.limitSelection);
    console.log('Item clicked: ', item); 
    console.log('Item found: ', this.isSelected(item));
    console.log('allowAdd: ', allowAdd)
    
    const found = this.isSelected(item);
    if(found)
      console.log('Item found: ', found);
    
    if (!found) {
      if (allowAdd) {
        this.writeValue(item[this._settings.textField ?? 'label'])
      }
    } else {
      this.selectedItems = '';
    }
    console.log('Item selected: ', this.selectedItems);
    this.closeDropdown();
    console.log(this._settings.singleOutput ? "Item Selezionato: " : "Item Selezionati: ", this.selectedItems)
  }

  writeValue(value: any) {
    this.selectedItems = value;
    this.onChangeCallback(value);
    this.cdr.markForCheck();
  }

  closeDropdownIfNotClickingInside(event: Event) {
    console.log("Click rilevato fuori dal dropdown", event.target);
    const clickedElement = event.target as HTMLElement;
    if (!clickedElement.closest('.multiselect-dropdown')) {
      console.log("Chiudo la tendina");
      this._settings.defaultOpen = false;
    }
  }
  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  // Set touched on blur
  @HostListener("blur")
  public onTouched() {
    // this.closeDropdown();
    this.onTouchedCallback();
  }

  trackByFn(index, item) {
    return item ? item[this._settings?.idField ?? 'id'] : null;
  }

  isSelected(clickedItem: any) {
    let found = false;
    if(!clickedItem ) return false;
    if (!this._settings.singleOutput && Array.isArray(this.selectedItems)) {
      this.selectedItems?.forEach(item => {
        if (item && clickedItem[this._settings?.idField ?? 'id'] === item[this._settings?.idField ?? 'id']) {
          found = true;
        }
      });
    }
    else if (this.selectedItems && !Array.isArray(this.selectedItems)  && clickedItem[this._settings?.idField ?? 'id'] == this.selectedItems[this._settings?.idField ?? 'id']) {
      found = true;
    }
    else if (this.selectedItems && !Array.isArray(this.selectedItems) && clickedItem[this._settings?.idField ?? 'id'] == '' && this.selectedItems[this._settings?.idField ?? 'id'] == null) {
      console.log('temp: selected items', this.selectedItems)
      found = true;
    }
    else if (this.selectedItems == clickedItem[this._settings?.textField ?? 'label'])
      found = true;
    return found;
  }

  isLimitSelectionReached(): boolean {
    return this._settings.limitSelection === this.selectedItems?.length;
  }

  isAllItemsSelected(): boolean {
    // get disabld item count
   // let filteredItems = this.listFilterPipe.transform(this._data,this.filter, this._settings);
    const itemDisabledCount = this._data.filter(item => item[this._settings?.disabledField ?? 'isDisabled']).length;
    // take disabled items into consideration when checking
    if ((!this.data || this.data.length === 0) && this._settings.allowRemoteDataSearch) {
      return false;
    }
    return this._data.length === this.selectedItems?.length + itemDisabledCount;
  }

  showButton(): boolean {
    if (!this._settings.singleSelection) {
      if (this._settings.limitSelection > 0) {
        return false;
      }
      // this._settings.enableCheckAll = this._settings.limitSelection === -1 ? true : false;
      return true; // !this._settings.singleSelection && this._settings.enableCheckAll && this._data.length > 0;
    } else {
      // should be disabled in single selection mode
      return false;
    }
  }

  itemShowRemaining(): number {
    return this.selectedItems?.length ?? 0 - this._settings.itemsShowLimit;
  }

  addSelected(item: ListItem) {
    if (this._settings.singleSelection && this._settings.singleOutput) {
      this.selectedItems = item;
    } else if (this._settings.singleSelection && !this._settings.singleOutput) {
      this.selectedItems = [];
      this.selectedItems.push(item);
    } else {
      this.selectedItems?.push(item);
    }
    this.onChangeCallback(this.emittedValue(this.selectedItems));
    this.onSelect.emit(this.emittedValue(item));
  }

  removeSelected(itemSel: ListItem) {
    if(!this._settings.singleOutput)
      this.selectedItems?.forEach(item => {
        if (itemSel[this._settings?.idField ?? 'id'] === item[this._settings?.idField ?? 'id']) {
          this.selectedItems?.splice(this.selectedItems?.indexOf(item), 1);
        }
      });
    else
      this.selectedItems = undefined;
    this.onChangeCallback(this.emittedValue(this.selectedItems));
    this.onDeSelect.emit(this.emittedValue(itemSel));
  }

  emittedValue(val: any): any {
    // const selected = [];
    // if (Array.isArray(val)) {
    //   val.map(item => {
    //     selected.push(this.objectify(item));
    //   });
    // } else {
    //   if (val) {
    //     return this.objectify(val);
    //   }
    // }
    return val;
  }

  // objectify(val: ListItem) {
  //   if (this._sourceDataType === 'object') {
  //     const obj = {};
  //     obj[this._settings.idField] = val.id;
  //     obj[this._settings.textField] = val.text;
  //     if (this._sourceDataFields.includes(this._settings.disabledField)) {
  //       obj[this._settings.disabledField] = val.isDisabled;
  //     }
  //     return obj;
  //   }
  //   if (this._sourceDataType === 'number') {
  //     return Number(val.id);
  //   } else {
  //     return val.text;
  //   }
  // }

  toggleDropdown(evt) {
    evt.preventDefault();
    if (this.disabled && this._settings.singleSelection)
      return;
    if (this._settings.defaultOpen)
      this.onDropDownClose.emit();
    this._settings.defaultOpen = !this._settings.defaultOpen;
  }

  closeDropdown() {
    this._settings.defaultOpen = false;
    // clear search text
    if (this._settings.clearSearchFilter) {
      this.filter.text = "";
    }
    this.onDropDownClose.emit();
  }

  toggleSelectAll() {
    if (this.disabled) {
      return false;
    } if (!this.isAllItemsSelected()) {
      // filter out disabled item first before slicing
      this.selectedItems = this._data.filter(item => !item[this._settings?.disabledField ?? 'isDisabled']).slice();
      this.onSelectAll.emit(this.emittedValue(this.selectedItems));
    } else {
      this.selectedItems = [];
      this.onDeSelectAll.emit(this.emittedValue(this.selectedItems));
    }
    this.onChangeCallback(this.emittedValue(this.selectedItems));
  }

  getFields(inputData) {
    const fields = [];
    if (typeof inputData !== "object") {
      return fields;
    }
    for (const prop in inputData) {
      fields.push(prop);
    }
    return fields;
  }

  SelectFirst() {

    // var data = this._data.filter((item: ListItem) => this._data);
    //  if(!(data?.length > 0)) return;
    //  this.onItemClick(undefined,data[0]);
  }
}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'AutoCopleteListPipe',
  pure: false
})
export class AutoCopleteListPipe implements PipeTransform {
  transform(items: ListItem[], filter: string, _settings: IDropdownSettings): ListItem[] {
    if (!items || !filter) return items;
    return items?.filter((item: ListItem) => this.applyFilter(item, filter, _settings)) ?? [];
  }

  public applyFilter(item: ListItem, filter: string, _settings: IDropdownSettings): boolean {
    var filtered = false;
    return !this.isExcludedId(_settings, item) && !(((item ?? {})[_settings?.textField ?? 'text'] ?? '').toLowerCase().indexOf(filter.toLowerCase()) === -1)

    // if (typeof item[_settings?.textField ?? 'text'] === 'string' && typeof filter === 'string') {
    //   filtered = !(filter[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'].toLowerCase().indexOf(filter.toLowerCase()) === -1);
    // } else {
    //   filtered = !(filter[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'].toString().toLowerCase().indexOf(filter.toString().toLowerCase()) === -1);
    // }
    // return filtered && (item[_settings?.idField ?? 'id'] || item[_settings?.idField ?? 'id'] == false) && !this.isExcludedId(_settings, item);

  }

  public isExcludedId(_settings: IDropdownSettings, item: ListItem): boolean {
    return _settings?.excludeIds?.includes(item[_settings?.idField ?? 'id'])
  }
}
