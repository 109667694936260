<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-lg-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)">
            <i class="ti-menu" [style.color]="LightenColor(environment.navbarbg ?? '#FFFFFF')"></i>
        </a>
    </li>
</ul>
<ul class="navbar-nav float-right" *ngIf="environment['style'] != 'FortyFix'">
<!--  <li class="nav-item ">-->
<!--    <a href="javascript:void(0)" class="btn-dark btn btn-circle btn-lg"-->
<!--        (click)="customizer = !customizer">-->
<!--        <i class="fa fa-spin fa-cog"></i>-->
<!--    </a>-->
<!--  </li>-->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown"
       role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="flag-icon flag-icon-{{selectedLanguage.icon}}"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" ngbDropdownMenu>
            <span class="dropdown-item" href="#" *ngFor="let lang of languages" (click)="changeLanguage(lang)">
                <i class="flag-icon flag-icon-{{lang.icon}}"></i>
                <span>{{lang.language}}
                  <span *ngIf="lang.type">({{lang.type}})</span>
                </span>
            </span>
    </div>
  </li>
  <app-notifications-badge></app-notifications-badge>
  <app-user-dropdown></app-user-dropdown>

</ul>


<ul class="navbar-nav float-right" *ngIf="environment['style'] == 'FortyFix'">

  <li class="nav-item dropdown" ngbDropdown placement="bottom-right" *ngIf="!environment['availableLang'] || environment['availableLang'].length > 1">
    <a ngbDropdownToggle class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown2"
       role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="flag-icon flag-icon-{{selectedLanguage.icon}}"></i>
    </a>
    <div  class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown2" ngbDropdownMenu>
            <span class="dropdown-item" href="#" *ngFor="let lang of languages" (click)="changeLanguage(lang)">
                <i class="flag-icon flag-icon-{{lang.icon}}"></i>
                <span>{{lang.language}}
                  <span *ngIf="lang.type">({{lang.type}})</span>
                </span>
            </span>
    </div>
  </li>
  <li class="d-flex justify-content-between align-items-center m-3">
    <div class="icon position-relative">
      <i-feather style="stroke: var(--main-color)" name="message-circle" class="align-items-center feather-lg"></i-feather>
    </div>
  </li>
  <li class="d-flex justify-content-between align-items-center m-3">
   
    <div class="icon">
      <i-feather style="stroke: var(--main-color)" name="bell" class="align-items-center feather-lg"></i-feather>
    </div>
  </li>
  <li class="ciao-utente d-flex justify-content-between align-items-center m-3" (click)="Profilo()">
      <div class="icon"  >
        <i-feather style="stroke: var(--main-color)" name="user" class="align-items-center feather-lg"></i-feather>
      </div>
      <span style="color: #666666">Ciao {{authenticationService?.currentUserValue?.fullName}}!</span>
  </li>
  <li class="logout-button d-flex justify-content-between align-items-center" (click)="LogOut()">
    <span style="color: var(--main-color)">Logout</span>
  </li>


</ul>
