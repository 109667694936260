<div class="modal-header" *ngIf="IsModal">
  <h5 class="modal-title" id="editUserLabel" style="font-weight: bold">{{selected.padre ? 'Intervento' : 'RMA.title' | translate }} {{selected?.progressivo}} {{selected?.nome}}</h5>
  <button type="button" class="close" (click)="cancelClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div [class.modal-body]="IsModal">
  <ng-container *ngIf="environment.RmaAddOn['SezioniRmaEdit']">
  <app-sector-viewer [(DropdownSources)]="DropdownSources" (OnFilter)="FilterHandler($event)" (OnChange)="ChangeHandler($event)" [(Campi)]="environment.RmaAddOn['SezioniRmaEdit']" [(Oggetto)]="selected"></app-sector-viewer>
  <div class="row" style="    margin-inline: 0.75rem;">
  <div class="col-12 mb-2" *ngIf="selected?.ricevutaId">
    <div class="input-group">
      <div class="input-group-prepend" style="display: flex; flex-direction: column; align-items: center;">
        <div class="input-group-text" style="min-width: 90px;">
          Ricevuta
        </div>
        &nbsp;
        <a href="javascript:void(0)" class="link" (click)='download(selected.ricevutaId)'>
          <i-feather name="download" class="feather-sm"></i-feather>
        </a>
        &nbsp;
        <a href="javascript:void(0)" class="link font-16 text-info font-medium " (click)="view(selected.ricevutaId)">
          <i class="fas fa-eye"></i>
        </a>
      </div>
      <div class="drop-fill" style="border: 1px solid #ced4da;">
        <img style="max-height: 200px; width: auto;"
          [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.ricevutaId?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
          alt="" />
        <pdf-viewer
          [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.ricevutaId?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
          [render-text]="true" [external-link-target]="'blank'"
          style=" display: block; width: 100%; height: 200px; "></pdf-viewer>
      </div>
    </div>   
  </div>

  <div class="col-12 mb-2" *ngIf="selected?.targhettaId">
    <div class="input-group">
      <div class="input-group-prepend" style="display: flex; flex-direction: column; align-items: center;">
        <div class="input-group-text" style="min-width: 90px;">
          {{'RMA.downloadTarghetta' | translate }}
        </div>
        &nbsp;
        <a href="javascript:void(0)" class="link" (click)='download(selected.targhettaId)'>
          <i-feather name="download" class="feather-sm"></i-feather>
        </a>
        &nbsp;
        <a href="javascript:void(0)" class="link font-16 text-info font-medium " (click)="view(selected.targhettaId)">
          <i class="fas fa-eye"></i>
        </a>
      </div>
      <div class="drop-fill" style="border: 1px solid #ced4da;">
        <img style="max-height: 200px; width: auto;"
          [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.targhettaId?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
          alt="" />
        <pdf-viewer
          [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.targhettaId?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
          [render-text]="true" [external-link-target]="'blank'"
          style=" display: block; width: 100%; height: 200px; "></pdf-viewer>
      </div>
    </div>
  </div>
  <div class="col-12 mb-2" *ngIf="selected?.estensione">
    <div class="input-group">
      <div class="input-group-prepend" style="display: flex; flex-direction: column; align-items: center;">
        <div class="input-group-text" style="min-width: 90px;">
          {{'RMA.downloadTarghetta' | translate }}
        </div>
        &nbsp;
        <a href="javascript:void(0)" class="link" (click)='download(selected.estensione.id)'>
          <i-feather name="download" class="feather-sm"></i-feather>
        </a>
        &nbsp;
        <a href="javascript:void(0)" class="link font-16 text-info font-medium " (click)="view(selected.estensione.id)">
          <i class="fas fa-eye"></i>
        </a>
      </div>
      <div class="drop-fill" style="border: 1px solid #ced4da;">
        <img style="max-height: 200px; width: auto;"
          [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.estensione.id?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
          alt="" />
        <pdf-viewer
          [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.estensione.id?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
          [render-text]="true" [external-link-target]="'blank'"
          style=" display: block; width: 100%; height: 200px; "></pdf-viewer>
      </div>
    </div>
  </div>
</div>
<div class="card-body">
<h5 class="card-title ng-star-inserted" *ngIf="environment.RmaAddOn.dfinee"
  style="font-weight: 700; padding: 0.8rem; background-color: whitesmoke; border-left: 5px solid #1898c6; max-width: 71%; color: #1898c6;">
  DETTAGLIO DELL'INTERVENTO</h5>
  <div class="row">
  
    <div class="col-lg-3"
      *ngIf="selected.id && environment.RmaAddOn.dfinep && !permission.isOperativeRole('C') && !permission.isOperativeRole('AC')">
      <label for="fp" class="mb-0 text-dark control-label col-form-label">{{'RMA.dfinep' | translate }} </label>
      <input type="datetime-local" #fp="ngModel" [readOnly]="!selected.data_inizio_prevista" name="fp" required
        [(ngModel)]="selected.data_fine_prevista" id="fp" class="form-control">
    </div>
    <div class="col-lg-3"
      *ngIf="selected.id && environment.RmaAddOn.diniziop &&  !permission.isOperativeRole('C') && !permission.isOperativeRole('AC')">
      <label for="ie" class="mb-0 text-dark control-label col-form-label">{{'RMA.diniziop' | translate }}:</label>
      <input type="datetime-local" #ie="ngModel" name="ie" required [(ngModel)]="selected.data_inizio_prevista" id="dip"
        class="form-control">
    </div>
    <div class="col-lg-3" *ngIf="selected.id && environment.RmaAddOn.dapertura_c">
      <label for="dac" class="mb-0 text-dark control-label col-form-label">{{'RMA.dapertura_c' | translate }} </label>
      <input type="datetime-local" #fp="ngModel" [readOnly]="true" name="fp" required
        [(ngModel)]="selected.data_apertura_centro" id="dac" class="form-control">
    </div>
    <div class="col-lg-3"
      *ngIf="selected.id && environment.RmaAddOn.dinizioe &&  !permission.isOperativeRole('C') && !permission.isOperativeRole('AC')">
      <label for="ie" class="mb-0 text-dark control-label col-form-label">{{'RMA.dInizioe' | translate }}:</label>
      <input type="datetime-local" #ie="ngModel" name="ie" required [(ngModel)]="selected.data_inizio_effettiva" id="ie"
        class="form-control">
    </div>
    <div class="col-lg-3"
      *ngIf="selected.id && environment.RmaAddOn.dfinee && !permission.isOperativeRole('C') && !permission.isOperativeRole('AC')">
      <label for="ie" class="mb-0 text-dark control-label col-form-label">{{'RMA.dfinee' | translate }} </label>
      <input type="datetime-local" #ie="ngModel" [readOnly]="!selected.data_inizio_effettiva" name="fe" required
        [(ngModel)]="selected.data_fine_effettiva" id="fe" class="form-control">
    </div>
    <div class="col-lg-3" *ngIf="selected.id && environment.RmaAddOn.Assistenza.Tecnico">
      <label for="dat" class="mb-0 text-dark control-label col-form-label">{{'RMA.dassegnazione_t' | translate }} </label>
      <input type="datetime-local" #fp="ngModel" [readOnly]="true" name="fp" required
        [(ngModel)]="selected.data_assegnazione_tec" id="dat" class="form-control">
    </div>
    <div class="col-lg-2" *ngIf="environment.RmaAddOn.Garanzia">
      <label for="garanzia" class="mb-0 text-dark control-label col-form-label">{{'RMA.garanzia' | translate }}:</label>
      <input type="checkbox" #garanzia="ngModel" name="garanzia" [(ngModel)]="selected.garanzia" id="garanzia"
        class="form-control">
    </div>
    <div class="col-lg-2" *ngIf="selected.id && !permission.isOperativeRole('C') && !permission.isOperativeRole('AC') && !environment.RmaAddOn['hidePriorita']">
      <label for="priorita" class="mb-0 text-dark control-label col-form-label">{{'RMA.priorita' | translate }}</label>
      <input type="checkbox" #garanzia="ngModel" name="garanzia" [(ngModel)]="priorita" id="priorita"
        class="form-control">
    </div>
    <div class="col-lg-2"
      *ngIf="environment.RmaAddOn.chiuso && !this.permission.hasOperativeRoles(environment.ClientiType)">
      <label for="chiuso" class="mb-0 text-dark control-label col-form-label">{{'RMA.chiuso' | translate }}:</label>
      <input type="checkbox" #garanzia="ngModel" name="garanzia" [(ngModel)]="selected.chiuso" id="chiuso"
        class="form-control">
    </div>
    <div class="col-lg-12" *ngIf="selected.id && !this.permission.hasOperativeRoles(['C', 'AC', 'CA'])">
      <label for="note" class="mb-0 text-dark control-label col-form-label">{{'RMA.noteInterne' | translate }}</label>
      <textarea type="text" #note="ngModel" [readOnly]="!selected.id" rows="10" name="note"
        [(ngModel)]="selected.noteTecnico" id="notet" class="form-control"></textarea>
      <small class="form-text text-danger" *ngIf="!note.valid ">{{'RMA.required' | translate}}</small>
    </div>
    <br>
    <div class="col-lg-12"></div>
  
  
  </div>
</div>

    <div style="    margin-inline: 0.75rem;" *ngIf="environment.RmaAddOn['ProveAcquistoMultipleRma'] && selected?.acquirente" >
      <div class="row" style="background-color: #87858525; padding-bottom: 20px;">
        <div class="col-lg-3 d-flex align-items-end" *ngIf="selected?.sedeId">
          <label for="categoria" class="control-label col-form-label">
            <span class="d-flex align-items-center p-2 font-weight-bold font-14">
                {{'provaAcquisto.title'|translate}}: &nbsp;
                <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14">{{selected.proveObject.length}}</span>
            </span>
          </label>
        </div>
        <div class="col-lg-6" *ngIf="selected?.sedeId">
          <ng-multiselect-dropdown [settings]="PAdropdownSettings" [placeholder]="'Seleziona'"
                                   [data]="ApprestamentiSelezionabili()" [(ngModel)]="selectedPA" >
          </ng-multiselect-dropdown>
        </div>
        <div class="col-lg-3 d-flex align-items-end" *ngIf="selected?.sedeId">
          <div class="d-flex align-items-end">
            <button type="submit" (click)="addPA(selectedPA)" class="ml-auto btn btn-outline-dark p-2 waves-effect waves-light">
              <span class="ml-1"> Aggiungi </span> &nbsp;
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
        </div>
      </div>
      <app-lista-prova-acquisto [(List)]="selected.proveObject" [Headers]="environment.RmaAddOn['ListProvaAcquistoRmaField']" [Cliente]="selected.acquirente" [isModifyDisabled]="true" [Local]="true" [HasFilter]="false" [HasHeader]="false" [(Sede)]="selected.sedeId" ></app-lista-prova-acquisto>
    </div>
    <div style="    margin-inline: 0.75rem;" *ngIf="selected?.id && environment.RmaAddOn['InterventiFigli'] && ! selected?.padre ">
      <div class="d-md-flex align-items-center  p-2" style="background-color: #ffffff;">
        <div class="ml-auto mt-3 mt-md-0" *ngIf="!permission.isOperativeRole('CA')">
          <button type="button" (click)="AddRma()" [disabled]="!CanAddFigli()" style="margin-bottom: -90px;" [ngbTooltip]="CanAddFigli()? '': 'Per Aggiungere un intervento bisogna aver spuntato i 4 check di controllo'"  class="btn btn-cyan rounded-pill p-2" ><i
            class="fas fa-plus  font-14 mr-1 text-white"></i>
            <span class="ml-1 text-white font-weight-bold">{{'RMA.add' | translate}}</span>
          </button>
        </div>
      </div>
        <app-rma-list  [(Padre)]="selected.id" [filtri]="{page:0, size:100, tipo: 'R', figli: true}"></app-rma-list>
    </div>
    <app-esiti-rma style="    margin-inline: 0.75rem;" *ngIf="environment.RmaAddOn['sottoTask'] && selected?.id && !selected.padre && permission.isPermitted('gestione-segnalazione') && !permission.isOperativeRoles(['C','AC']) && environment.RmaAddOn.sottoTask" [(ListaScadenze)]="selected.figli" [editEnabled]=" !environment.RmaAddOn['esitoLock'] || (permission.itsme_mario(selected?.managerObject?.id) &&  (!selected['chkDoc'] || permission.isOperativeRoles(['AZ'])) && !(permission.HasCapacity(['tecnico']) && !selected['data_assegnazione_tecnico']))" [checkSelezionate]="selected.attivita" (AddButtonClick)="EsitoAddButton()" (Updated)="Refresh()"></app-esiti-rma>
  <div class="row" style="    margin-inline: 0.75rem;"
    *ngIf="selected?.assistenza && environment.RmaAddOn.Assistenza.Enabled && !permission.isOperativeRoles(['C', 'AC'])">
    <div class="col-lg-8" >
      <label for="categoria" class="control-label col-form-label">{{'RMA.ca.title' | translate }}:</label>
      <ng-multiselect-dropdown #categoria="ngModel" [disabled]="this.permission.isOperativeRoles(['CA', 'C', 'AC'])"
        [settings]="ClienteDropdownSettings" id="ass" name="categoria" [data]="Assistenze" [(ngModel)]="Assistenza"
        (ngModelChange)="CentroAssistenzaCambiato()">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-lg-1 d-flex align-items-end">
      <a href="javascript:void(0)" class="link font-16 text-danger font-medium mr-2" (click)="OpenMap()">
        <i class="fas fa-map"></i>
      </a>
      <a *ngIf="selected?.assistenza?.email"
        href="mailto:{{selected.assistenza.email}}?subject=Ticket{{selected?.id?.toString()}}"
        class="link font-16 text-success font-medium mr-2">
        <i class="fas fa-address-card"></i>
      </a>
    </div>
  
    <div class="col-lg-3">
      <label for="categoria" *ngIf="selected.id  && Assistenza?.length > 0 && environment.RmaAddOn.Assistenza.Tecnico"
        class="control-label col-form-label">{{'RMA.tecnico' | translate }}:</label>
      <ng-multiselect-dropdown *ngIf="selected.id && Assistenza?.length > 0 && environment.RmaAddOn.Assistenza.Tecnico"
        required #tecnico="ngModel" [settings]="TecniciDropdownSettings" [placeholder]="'Seleziona'" id="tecnico"
        name="tecnico" [data]="tecnici" [(ngModel)]="selectedTecnici" (ngModelChange)="onTecnicoChanged()">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Referente">
      <label for="referenteaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.referente' |
        translate}}:</label>
      <input type="text" #referente="ngModel" name="referente" required [(ngModel)]="selected.assistenza.referente"
        id="referenteaass" class="form-control">
      <small class="form-text text-danger" *ngIf="!referente.valid">{{'RMA.required' | translate }}</small>
    </div>
    <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Nominativo">
      <label for="nominativoaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.nominativo' |
        translate}}:</label>
      <input type="text" #nominativo="ngModel" name="nominativo" required [(ngModel)]="selected.assistenza.nominativo"
        id="nominativoaass" class="form-control">
      <small class="form-text text-danger" *ngIf="!nominativo.valid">{{'RMA.required' | translate }}</small>
    </div>
    <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Indirizzo">
      <label for="indirizzoaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.indirizzo' |
        translate}}:</label>
      <input type="text" #indirizzo="ngModel" required name="indirizzo" [(ngModel)]="selected.assistenza.indirizzo"
        id="indirizzoaass" class="form-control">
      <small class="form-text text-danger" *ngIf="!indirizzo.valid">{{'RMA.required' | translate }}</small>
    </div>
    <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Citta">
      <label for="cittaaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.citta' | translate}}:</label>
      <input type="text" #citta="ngModel" name="citta" required [(ngModel)]="selected.assistenza.citta" id="cittaaass"
        class="form-control">
      <small class="form-text text-danger" *ngIf="!citta.valid">{{'RMA.required' | translate }}</small>
    </div>
    <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Cap">
      <label for="capaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.cap' | translate}}:</label>
      <input type="text" #cap="ngModel" name="cap" required [(ngModel)]="selected.assistenza.cap" id="capaass"
        class="form-control">
      <small class="form-text text-danger" *ngIf="!cap.valid">{{'RMA.required' | translate }}</small>
    </div>
    <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Provincia">
      <label for="provinciaaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.provincia' |
        translate}}:</label>
      <input type="text" #provincia="ngModel" name="provincia" required [(ngModel)]="selected.assistenza.provincia"
        id="provinciaaass" class="form-control">
      <small class="form-text text-danger" *ngIf="!provincia.valid">{{'RMA.required' | translate }}</small>
    </div>
    <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Telefono">
      <label for="telefonoaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.telefono' |
        translate}}:</label>
      <input type="text" #telefono="ngModel" name="telefono" required [(ngModel)]="selected.assistenza.telefono"
        id="telefonoaass" class="form-control">
      <small class="form-text text-danger" *ngIf="!telefono.valid">{{'RMA.required' | translate }}</small>
    </div>
    <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Email">
      <label for="emaila" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.email' | translate}}:</label>
      <input type="text" #email="ngModel" name="email" required [(ngModel)]="selected.assistenza.email" id="emaila"
        class="form-control">
      <small class="form-text text-danger" *ngIf="!email.valid">{{'RMA.required' | translate }}</small>
    </div>
  </div>
    <div style="    margin-inline: 0.75rem;" *ngIf="selected?.id && selected?.photo?.length > 0 && environment.RmaAddOn.Allegati">
    <div class="d-md-flex align-items-center bg-white p-2">
      <h4 class="d-flex align-items-center p-2 ">{{'RMA.allegati' | translate}}</h4>
    </div>
    <div class="table-responsive" *ngIf="selected.photo">
      <table class="table bg-white table-hover align-middle mb-0 no-wrap">
        <tbody>
          <tr *ngFor="let photo of selected.photo; let i = index">
            <td class="align-middle">
              <a href="javascript:void(0)" class="link font-16 text-info font-medium mr-2" (click)="download(photo)">
                <i class="fas fa-download"></i>
              </a>
              <a href="javascript:void(0)" class="link font-16 text-info font-medium mr-2" (click)="view(photo)">
                <i class="fas fa-eye"></i>
              </a>
            </td>
            <td>
              <div class="el-element-overlay">
                <div class="el-card-item">
                  <div class="el-card-avatar el-overlay-1" style="max-height: 200px; width: 500px;">
                    <img style="max-height: 200px; width: auto;"
                      [src]="apiPaths?.getBaseAPI() + '/file/download/' + photo?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
                      alt="" />
                    <pdf-viewer
                      [src]="apiPaths?.getBaseAPI() + '/file/download/' + photo?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
                      [render-text]="true" [external-link-target]="'blank'" style="display: block;"
                      style="width: 100%; height: 200px;">
                    </pdf-viewer>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <app-voci-costo style="    margin-inline: 0.75rem;"
    *ngIf="selected?.id && selected?.voci && environment.RmaAddOn.vociDiCosto && !permission.isOperativeRoles(['C','AC'])"
    [(myProp)]="selected.voci"></app-voci-costo>
  <app-attivita-rma style="    margin-inline: 0.75rem;"
    *ngIf="selected?.id && permission.isPermitted('gestione-segnalazione') && !permission.isOperativeRoles(['C','AC']) && environment.RmaAddOn['sottoTaskrma']"
    [(ListaScadenze)]="selected.figli" (AddButtonClick)="ScadAddButton()" (Updated)="Refresh()"></app-attivita-rma>

  </ng-container>
</div>

<div class="d-flex align-items-center justify-content-end m-t-10 m-b-10 m-r-10" *ngIf="this.permission.isPermitted('gestione-rma') || this.permission.isOperativeRoles(['CA'])">
  <button (click)="CaricaModello()" *ngIf="environment.RmaAddOn['Modelli'] && permission.HasCapacity(['gestione-commesse-incorso']) && !selected['chkDoc']&& !selected['chiuso']" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> Carica Modello </span>
    <i-feather name="upload" class="feather-md "></i-feather>
  </button>
  <button type="submit" *ngIf="showAnagrafica && !permission.isOperativeRoles(['AG']) || this.permission.isOperativeRoles(['CA'])"  (click)="save()" class=" btn btn-success   waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> {{'RMA.save' | translate}} </span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
  <button type="submit" *ngIf="showAnagrafica && !permission.isOperativeRoles(['AG', 'CA']) && environment.RmaAddOn['delete'] && this.permission.isPermitted('gestione-rma')"  (click)="delete()" class=" btn btn-danger   waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> {{'Elimina' | translate}} </span>
    <i-feather name="trash" class="feather-md "></i-feather>
  </button>
<!--  <a href="mailto:{{selected.clienteObject.pec ?? selected.clienteObject.email ?? 'noEmailFound@verificaspa.it'}}?subject={{'Accettazione Vs. proposta contrattuale per la verifica degli impianti elettrici di cui al DPR 462/01 del 22/10/2001 e assegnazione numero di pratica per impianto sito in:\n' }}&body={{getTextPec()}}" type="submit"  class=" btn btn-rounded btn-info p-2 waves-effect waves-light" *ngIf="hasPecButton()">-->
<!--    <span class="ml-1 text-white font-weight-bold"> Invia Pec </span>-->
<!--    <i class="far fa-address-card "></i>-->
<!--  </a>-->
  <ng-container *ngIf="environment.RmaAddOn['Pulsanti']">
    <ng-container *ngFor="let button of environment.RmaAddOn['Pulsanti']">
      <button type="button" [ngClass]="button['class']" *ngIf="permission.HasCapacity(button['capacita']) && campoUtility.ButtonShow(button,selected)" (click)='customButton(button)'>
        <i *ngIf="button['icon']" [ngClass]="button['icon']" class="font-14 mr-1 text-white"></i>
        <span [ngClass]="button['labelClass']"> {{button['nome'] | translate}} </span>
      </button>
    </ng-container>
  </ng-container>
  <button type="submit" *ngIf="!showAnagrafica && !permission.isOperativeRoles(['AG'])" (click)="avanti()" class=" btn  btn-success  waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> {{'RMA.save' | translate}} </span>
  </button>
  <app-stampa [Stampe]="stampe" *ngIf="stampe?.length > 0"></app-stampa>
</div>
