import { Files } from 'src/app/shared/models/File';
import { Ruolo } from './Ruolo';
import {ClassicType} from "../../shared/models/ClassicType";
export class Persona {
  id?             : number;
  nome?           : string = '';
  cognome?        : string = '';
  username?       : string;
  password?       : string;
  email?          : string;
  indirizzo?      : string;
  cap?            : string;
  citta?          : string;
  provincia?      : string;
  telefono?       : string;
  cellulare?      : string;
  codice_fiscale? : string;
  ruoli?:           string[];
  ruoliObject?: Ruolo[];
  ruoliAccesso?: Ruolo[];
  ruoliAccessoObject?: Ruolo[];
  enabled?: boolean;
  InModifica?:boolean;
  files?: Files[];
  fullName?: string;
  ConfermaPassword?: string;
  cliente: number;
  principale: boolean;
  two_factor?: boolean = false;
  close?: boolean;
  isSelected?: boolean;
  repartoObject?: ClassicType;
  reparto?: number;
  regioniObject?: any[];
  disabilita?: any;
}
