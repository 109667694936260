<li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="mdi mdi-bell-outline font-weight-bold font-24"></i>
        <div class="notify" *ngIf="notifiche.length"> <span class="heartbit"></span> <span class="point"></span> </div>
    </a>
    <div class="dropdown-menu dropdown-menu-right mailbox" *ngIf="notifiche.length" aria-labelledby="2" ngbDropdownMenu>
        <ul class="list-style-none">
            <li class="border-bottom">
                <div class="drop-title bg-info text-white"><h5 class="message-title">Hai {{notifiche.length}} {{notifiche && notifiche?.length > 1 ? "notifiche" : "notifica"}}
                </h5></div>
            </li>
            <li>
                <div  class="message-center message-body" style="height: auto;  overflow: auto;" [perfectScrollbar]="config" *ngFor="let notifica of notifiche">
                    <a  class="message-item" (click)="notifica.onClick(notifica)">
                        <span class="btn btn-circle btn-success" style="padding: 0">
                            <i class="mdi mdi-bell-outline font-24"></i>
                        </span>
                        <span class="mail-contnet">
                            <h5 class="message-title">{{notifica.value}}</h5>
                        </span>
                    </a>
                </div>
            </li>
            <!-- <li>
                <a class="nav-link text-center text-dark link" href="/scadenzario/scadenzario"
                    *ngIf="notifiche ">
                    <b>Vai al Calendario </b>
                    <i class="fa fa-angle-right"></i>
                </a>
            </li> -->
        </ul>
    </div>
</li>