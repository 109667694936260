<div tabindex="0" class="multiselect-dropdown" >
  <div >
    <input type="text" (keyup.enter)="SelectFirst()"  (focus)="_settings.defaultOpen = true"  aria-label="multiselect-search" class="form-control" [readOnly]="disabled"
      [placeholder]="_settings.searchPlaceholderText" [(ngModel)]="selectedItems"
      (ngModelChange)="onFilterTextChange($event)">
  </div>
  <div class="dropdown-list" [hidden]="!_settings.defaultOpen || ((_data | AutoCopleteListPipe:selectedItems : _settings).length == 0) ">
    <ul class="item2" [style.maxHeight]="_settings.maxHeight+'px'">
      <ng-container  *ngFor="let item of _data | AutoCopleteListPipe: selectedItems : _settings; let i = index;">
        <li class="multiselect-item-checkbox" (click)="onItemClick(item)">
          <input type="checkbox" [attr.aria-label]="item[_settings?.textField ?? 'label']" [checked]="isSelected(item)"
            [disabled]="disabled || (isLimitSelectionReached() && !isSelected(item)) || item[this._settings?.disabledField ?? 'isDisabled']" />
          <div>{{item[this._settings?.textField ?? 'label']}}</div>
        </li>
      </ng-container>
      
      <li class='no-filtered-data' *ngIf="_data.length != 0 && (_data | AutoCopleteListPipe:selectedItems : _settings).length == 0 && !_settings.allowRemoteDataSearch">
        <h6>{{_settings.noFilteredDataAvailablePlaceholderText}}</h6>
        <button class="btn btn-outline-primary" *ngIf="this._settings.moreButton" (click)="onMoreButtonClick.emit(selectedItems)">{{this._settings.moreButtonText ?? 'More'}}</button>
      </li>

      <li class='no-data' *ngIf="_data.length == 0">
        <h6>{{_settings.noDataAvailablePlaceholderText}}</h6>
        <button class="btn btn-outline-primary" *ngIf="this._settings.moreButton" (click)="onMoreButtonClick.emit(selectedItems)">{{this._settings.moreButtonText ?? 'More'}}</button>
      </li>
    </ul>
  </div>
</div>
