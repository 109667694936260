import { Component, Input, OnInit } from "@angular/core";
import { DynamicCrudService, OggettoConfig } from "../_services/dynamic-crud.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { CampoAggiuntivo, CampoUtility } from "../models/campo-aggiuntivo";
import { Permission } from "src/app/Login/_guards/Permission";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DynamicEditComponent } from "../dynamic-edit/dynamic-edit.component";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-dynamic-list',
  templateUrl: './dynamic-list.component.html'
})
export class DynamicListComponent implements OnInit {
  CrudSettings: OggettoConfig = undefined;
  @Input() CrudId: string;
  environment = environment;
  @Input() openFilter: boolean = false;
  List: any[] = [];
  OriginalList: any[] = [];
  private ordine: { field: string, order: 'asc' | 'desc' } = { field: '', order: 'asc' };
  @Input() title: string = 'List';
  @Input() FilterViewType: 'normal' | 'sidebar' = 'normal';
  @Input() filtro: any = {}
  @Input() RowClickEnabled: boolean = true;
  @Input() ConcatParams: any = {};
  constructor(
    private crudService: DynamicCrudService,
    private activeRoute: ActivatedRoute,
    public campoUtility: CampoUtility,
    private permission: Permission,
    private modalService: NgbModal,
    private titleService: Title,
    public translate: TranslateService,
    private router: Router,
  ) { }

  ngOnInit() {
    if (!this.CrudId)
      this.activeRoute.params.subscribe(
        (params: { type: string }) => {
          this.CrudSettings = this.crudService.GetCrudSettings(params.type);
          this.Refresh();
        }
      );
    else {
      this.CrudSettings = this.crudService.GetCrudSettings(this.CrudId);
      this.Refresh();
    }
  }
  Refresh() {
    const filter = { page: 0, size: 10, ...DeepCopy(this.CrudSettings.ListDefaultFilter) };
    const filtro = Object.assign(filter, this.filtro);
    if (!this.CrudId)
      this.updateTitle();
    this.crudService.GetList(Object.assign({}, this.CrudSettings), filtro).subscribe(response => {
      this.List = this.CrudSettings.ListResponseHasData ? response.data : response;
      this.OriginalList = Object.assign([], this.List);
    })
  }
  hasListActions() {
    return this.CrudSettings && this.CrudSettings['AzioniLista']?.filter(x => !x.single)?.length > 0;
  }
  MassiveButtonClick(button) {
    this.crudService.MultipleCustomButtonClick(this.List.filter(x => x['selected']), button, true, this.CrudSettings, (end) => this.Refresh(), (refresh) => { this.Refresh() }, (close) => { })
  }
  ButtonClick(item, button) {
    this.crudService.CustomButtonClick(item, button, true, this.CrudSettings, (end) => this.Refresh(), (refresh) => { this.Refresh() }, (close) => { })
  }
  dynamicHeader() {
    return this.CrudSettings?.Headers;
  }
  IsArray(val) {
    return val && Array.isArray(val);
  }
  headShow(campo): boolean {
    return true;
  }
  allSelected(headerField: any, data: any[]) {
    return data?.every(x => x[headerField]);
  }

  toggleAll(headerField: any, data: any[]) {
    var oldValue = Object.assign({}, { val: this.allSelected(headerField, data) })
    data?.forEach((x) => x[headerField] = !oldValue.val)
  }
  RowClick(Item) {
    if (!this.RowClickEnabled || this.dynamicHeader()?.some(x => x.modificabile)) return;
    this.OpenEdit(Item);
  }
  EditButton(Item) {
    this.OpenEdit(Item);
  }
  HasAddButton() {
    return this.CrudSettings?.HasAddList &&
      this.campoUtility.CheckValue(this.CrudSettings?.HasAddList, Object.assign({}, this.CrudSettings.ListDefaultFilter, this.filtro)) &&
      this.permission.HasCapacity(this.CrudSettings.Capacita);
  }
  AddClick() {
    this.OpenEdit()
  }
  OpenEdit(Item?: Object) {
    if (!this.CrudSettings.EditView)
      this.OpenEditModal(Item)
    else
      switch (this.CrudSettings.EditView) {
        case 'Modal':
          this.OpenEditModal(Item);
          break;
        case 'Page':
          this.OpenEditPage(Item);
          break;
      }
  }
  OpenEditModal(Item?: Object) {
    const EditModal = this.modalService.open(DynamicEditComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl'
    });
    (<DynamicEditComponent>EditModal.componentInstance).CrudSettings = this.CrudSettings;
    (<DynamicEditComponent>EditModal.componentInstance).Item = Item ?? Object.assign(this.CrudSettings.AddDefaultObject ?? {}, this.ConcatParams);
    (<DynamicEditComponent>EditModal.componentInstance).updated.subscribe(data => {
      setTimeout(() => { this.Refresh(); }, 1000)
    });
  }
  OpenEditPage(Item?: any) {
    var params = [this.CrudSettings.Id, Item?.id];
    var link = `shared/dynamicedit/${params?.filter(x => x).join('/')}`;
    this.router.navigate([this.activeRoute.snapshot.queryParams[link] || link,]);
  }
  ExportCsv() {
    this.crudService.DownloadCsv(this.List, this.CrudSettings, `${this.CrudSettings.listTitle} Export`, {});
  }


  order(campo: CampoAggiuntivo) {
    var order: 'asc' | 'desc' = campo.nome == this.ordine.field ? (this.ordine.order == 'asc' ? 'desc' : 'asc') : 'asc'
    this.List = this.campoUtility.getSortedList(this.OriginalList, campo, order);
    this.ordine = { field: campo.nome, order: order }
  }

  private updateTitle() {
    this.title = this.CrudSettings.listTitle ?? 'Lista'
    this.titleService.setTitle(this.translate.instant(this.title));
  }
  Tooltip(item) {
    return ''
  }

  Stampe(item) {
    return item?.stampe?.filter(stampa => (!item?.template && item.tipo != "F") || stampa.id == item.template);
  }

}





function DeepCopy(item: any): any {
  return JSON.parse(JSON.stringify(item));
}