import { Component, Inject, Input, OnInit } from "@angular/core";
import { GlobalStoreService } from "../_services/global-store.service";

@Component({
    selector: 'app-notifications-badge',
    templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
    notifiche: any[];
    constructor(private store: GlobalStoreService) { }
    ngOnInit() {
        this.store.GetAllNotifications();
        this.store.$notifiche.subscribe(notifiche => this.notifiche = notifiche)
    }

}



