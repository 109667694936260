import {Component, AfterViewInit, EventEmitter, Output, Input} from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import {GlobalStoreService} from "../../shared/_services/global-store.service";
import { environment } from 'src/environments/environment';
import {AuthenticationService} from "../../Login/_services/authentication.service";
import {FileStoreService} from "../../shared/_services/file-store.service";
import {PersonaleStoreService} from "../../Admin/_service/personale-store.service";
import {Permission} from "../../Login/_guards/Permission";
import {ActivatedRoute, Router} from "@angular/router";
import {Color, hsl} from "d3";
declare var $: any;

@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html'
})
export class VerticalNavigationComponent implements AfterViewInit {



  environment = environment;
  @Output() toggleSidebar = new EventEmitter<void>();
  @Input() customizer: boolean = false;
  @Output() customizerChange = new EventEmitter<boolean>();
  public config: PerfectScrollbarConfigInterface = {};
  public selectedLanguage: any = {
    language: 'Italian',
    code: 'it',
    type: 'IT',
    icon: 'it'
  }

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
    {
      language: 'Español',
      code: 'es',
      icon: 'es'
    },
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr'
    },
    {
      language: 'German',
      code: 'de',
      icon: 'de'
    },
    {
      language: 'Italian',
      code: 'it',
      icon: 'it'
    }
  ]
  notifiche: any[];



  constructor(private modalService: NgbModal, private translate: TranslateService, public store: GlobalStoreService, public authenticationService: AuthenticationService,     private filestore: FileStoreService,
              private personaleStore: PersonaleStoreService,
              public permission: Permission,
              private route: ActivatedRoute,
              private router: Router) {
    translate.setDefaultLang('it');
    

  }

  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
    
  }

  ngAfterViewInit() { 
    this.store.GetAllNotifications();
    this.store.$notifiche.subscribe(notifiche=> this.notifiche = notifiche)
  }

  LogOut() {
    this.authenticationService.logout();
  }
  Profilo() {
    if(this.permission.isOperativeRoles(environment.ClientiType)) {
      this.router.navigate([this.route.snapshot.queryParams['Cliente/profile'] || 'Cliente/profile',])
      return;
    }
    this.personaleStore.updateEditable(true);
    this.personaleStore.updateShowAddForm(false);
    this.personaleStore.updateSelectedUserWhenProfile(this.authenticationService.currentUserValue);
    this.filestore.SelectSection('Profilo');
    this.router.navigate([this.route.snapshot.queryParams[' listautenti/profilo '] ||'listautenti/profilo',])
  }
  LightenColor(color: string, percent = 50) {
    if (!color || !color.includes('#'))  return hsl(0, 0, 100);
    var num = parseInt(color.toString().replace("#", ""), 16);
    if (num < 16750000)
      return hsl(0, 0, 100);
    else
      return hsl(0, 0, 0);
  };

}
