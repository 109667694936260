import { Injectable } from '@angular/core';
import {ApiPathsService} from "../../api-paths.service";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import {Permission} from "../../Login/_guards/Permission";
import { environment } from "../../../environments/environment";
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalStoreService {
  constructor(
              private apiPaths: ApiPathsService,
              public permission: Permission
  ) {
    this.setupSearchDebouncer();
    this.setupTriggeredDebouncer();
   }
  private environment = environment;
  subscriptions: Subscription = new Subscription();
  private NOTIFICHE = new BehaviorSubject<any[]>([]);
  private dataStore: { Notifiche: any[], } = { Notifiche: [] }
  readonly $notifiche = this.NOTIFICHE.asObservable();
  refreshNotifiche = () => this.NOTIFICHE.next(Object.assign({}, this.dataStore).Notifiche);
  private searchDecouncer$: Subject<string> = new Subject();
  private triggeredDebouncer$: Subject<string> = new Subject();
  updateNotifiche() {
    
  }


  private setupSearchDebouncer(): void {
    this.searchDecouncer$.pipe(
      debounceTime(500),
      //distinctUntilChanged(),
    ).subscribe((term: any) => {
       const Alerts = this.environment['Alerts'];
      if (!Alerts?.length) return;
      this.setNotifiche([]);
      Alerts.forEach(x=> this.GetAlerts(x));
    });
  }
  private setupTriggeredDebouncer(): void {
    this.triggeredDebouncer$.pipe(
      debounceTime(500),
      //distinctUntilChanged(),
    ).subscribe((trigger: any) => {
      this.setNotifiche(this.dataStore.Notifiche.filter(x => x.trigger != trigger));
      const Alerts = this.environment['Alerts'];
      if (!Alerts?.length) return;
      Alerts.filter(x => x.trigger == trigger)?.forEach(x => this.GetAlerts(x));
    });
  }


  GetAllNotifications() {
    this.searchDecouncer$.next();
  }

  GetTriggeredAlerts(trigger: string) {
    console.log("Alerts Refresh Triggered with: ", trigger);
    this.triggeredDebouncer$.next(trigger);
  }
  GetAlerts(item: any) {
    if (!item?.canRead(item, this?.permission)) return;
    this?.subscriptions.add(this?.apiPaths.ClassicGet(`${item.restRoute}`).subscribe(data => {
      this?.ConcatNotifiche(item.map(data)?.map(x => {
        if (typeof x == 'string')
          return Object.assign({}, item, {value: x})
        return Object.assign({}, item, x) 
      }), item);
    }))
  }

  setNotifiche(data: any[]) {
    this.dataStore.Notifiche = data;
    this.refreshNotifiche();
    console.log("Notifiche: ", this.dataStore.Notifiche);
  }
  ConcatNotifiche(data: any[], item?: any) {
    this.dataStore.Notifiche = [...this.dataStore.Notifiche?.filter(x => !item || item.restRoute != x.restRoute), ...data];
    this.refreshNotifiche();
  }
}
